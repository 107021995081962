import React from 'react';
import cx from 'classnames';

import styles from './InputLabel.module.css';

export interface InputLabelProps {
  label?: string;
  className?: string;
  block?: boolean;
  required?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
}

/**
 * Label
 * A Wrapper for an input, supplying some errorMessage and label handling
 * @param label label
 */

const InputLabel = ({
  disabled = false,
  label,
  block,
  required,
  className,
  children,
  error,
  ...props
}: InputLabelProps) => {
  return (
    <label
      className={cx(styles.root, {
        [styles.disabled]: disabled,
        [styles.block]: block,
        [styles.error]: error,
        className,
      })}
      {...props}
    >
      {label && (
        <span className={styles.label}>
          {label}
          {required && '*'}
        </span>
      )}
      {children}
    </label>
  );
};

export default InputLabel;
