import React from 'react';

import { cx } from 'utils';
import styles from './Main.module.css';

export type MainProps = {
  spacing?: 'extra';
  paddingTop?: boolean;
  className?: string;
} & React.HTMLProps<HTMLDivElement>;

const Main: React.FCWithChildren<MainProps> = ({
  children,
  className,
  spacing,
  paddingTop = false,
  ...rest
}) => (
  <main
    className={cx(
      styles.root,
      { [styles['spacing-extra']]: spacing === 'extra', [styles.paddingTop]: paddingTop },
      className,
    )}
    {...rest}
  >
    {children}
  </main>
);

export default Main;
