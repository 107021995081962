import React from 'react';

import Footer from 'containers/Footer';
import Nav from 'containers/Nav';

const Layout: React.FCWithChildren = ({ children }) => {
  return (
    <>
      <Nav />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
