import React from 'react';
import Link from 'next/link';

import Container from 'components/Container';
import ScreenReaderOnly from 'components/ScreenReaderOnly';
import { useApp } from 'contexts/AppContext';
import useEscape from 'hooks/useEscape';
import useRouteAway from 'hooks/useRouteAway';
import CloseSVG from 'icons/close.svg';
import LogoSVG from 'icons/logo.svg';
import SearchSVG from 'icons/search.svg';
import { cx } from 'utils';
import styles from './SearchBar.module.css';

export interface SearchBarInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'onSubmit'> {
  onChange?: (v: string) => void;
}

/** Wrapper around useKeyDown to make sure listeners are not added when closed */

const SearchBarInput: React.FCWithChildren<SearchBarInputProps & { onClose: () => void }> = ({
  onClose,
  onChange,
  ...props
}) => {
  const { searchOpen, setSearchOpen } = useApp();

  const ref = React.useRef<HTMLInputElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const el = ref.current;

    if (el) {
      document.body.style.backgroundColor = 'rgba(0,0,0,0.1)';
      el.focus();
    }

    return () => {
      document.body.style.backgroundColor = 'initial';
      el?.blur();
    };
  }, [ref]);

  useEscape(onClose);
  useRouteAway(onClose);

  // useClickOutside(wrapperRef, onClose);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.value);
  };

  return (
    <div className={styles.layout} ref={wrapperRef}>
      <Container className={styles.container}>
        <span className={styles.hideTabletPortraitDown}>
          <Link href="/" data-cy="top-nav-home">
            <LogoSVG alt="Jacksons.se" />
          </Link>
        </span>
        <div className={styles.inputContainer}>
          <SearchSVG className={cx(styles.searchIcon, styles.hideTabletPortraitDown)} />
          <input
            ref={ref}
            autoCapitalize="off"
            autoCorrect="off"
            autoComplete="off"
            className={cx(styles.input, styles.text)}
            name="search"
            type="text"
            onChange={handleChange}
            {...props}
          />
          <input type="submit" hidden />
          <label htmlFor="site-search">
            <ScreenReaderOnly>Search</ScreenReaderOnly>
          </label>
        </div>

        {searchOpen && (
          <button
            type="button"
            onClick={() => setSearchOpen(false)}
            className={cx(styles.button, styles.toggle, styles.cancel)}
          >
            <CloseSVG stroke="currentColor" />
            <p>CANCEL</p>
            <ScreenReaderOnly>Close Search</ScreenReaderOnly>
          </button>
        )}
      </Container>
    </div>
  );
};

export interface SearchBarProps extends SearchBarInputProps {
  onSubmit?: (s: string) => void;
}

const SearchBar: React.FCWithChildren<SearchBarProps> = ({ onSubmit, ...props }) => {
  const { searchOpen, setSearchOpen } = useApp();

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement> & { target: { search: HTMLInputElement } },
  ) => {
    e.preventDefault();
    onSubmit && onSubmit(e.target.search.value);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {searchOpen && (
          <SearchBarInput
            {...props}
            onClose={() => {
              setSearchOpen(false);
            }}
          />
        )}
      </form>

      {!searchOpen && (
        <button onClick={() => setSearchOpen(true)} className={cx(styles.button, styles.toggle)}>
          <SearchSVG stroke="currentColor" />
          <ScreenReaderOnly>Open Search</ScreenReaderOnly>
        </button>
      )}
    </>
  );
};

export default SearchBar;
