import React from 'react';

import Clickable, { ClickableProps } from 'components/Clickable';
import Typography from 'components/Typography';
import { cx } from 'utils';
import styles from './NavItem.module.css';

export interface NavItemProps extends ClickableProps {
  flush?: 'left' | 'right';
  active?: boolean;
}

const NavItem = React.forwardRef<HTMLAnchorElement & HTMLButtonElement, NavItemProps>(
  ({ children, flush, active = false, ...rest }, ref) => {
    return (
      <Clickable
        ref={ref}
        className={cx(styles.item, {
          [styles['flush-left']]: flush === 'left',
          [styles['flush-right']]: flush === 'right',
          [styles.active]: active,
        })}
        {...rest}
      >
        <Typography uppercase>{children}</Typography>
      </Clickable>
    );
  },
);

NavItem.displayName = 'NavItem';

export default NavItem;
