import React from 'react';

import Notification from 'components/Notification';
import Typography from 'components/Typography';
import { useEnquiries } from 'contexts/EnquiryContext';
import CartSVG from 'icons/cart.svg';
import styles from './ItemRequest.module.css';

const ItemRequest: React.FCWithChildren = () => {
  const { setOpen: setOpenEnquiryDialog, stored } = useEnquiries();

  return (
    <Notification>
      <button onClick={() => setOpenEnquiryDialog(true)}>
        <Typography className={styles.root} color="off-white">
          <CartSVG />

          <span>
            {stored.length} item{stored.length > 1 ? 's' : ''} request to the gallery
          </span>
        </Typography>
      </button>
    </Notification>
  );
};

export default ItemRequest;
