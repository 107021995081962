import React, { HTMLProps } from 'react';
import cx from 'classnames';

import styles from './Checkbox.module.css';

export interface CheckboxProps extends Omit<HTMLProps<HTMLInputElement>, 'type'> {
  block?: boolean;
  label?: string;
  disabled?: boolean;
  errorMessage?: string;
  type?: 'radio' | 'checkbox';
}

/**
 * Checkbox
 *
 * @param label label
 * @param disable the checkbox
 * @param block render as a block-level element
 * @param errorMessage warning/Error errorMessage
 * @param color color scheme
 */

const Checkbox: React.FCWithChildren<CheckboxProps> = ({
  className,
  disabled,
  errorMessage,
  label,
  type = 'checkbox',
  ...props
}) => {
  return (
    <label className={cx(styles.root, className)}>
      <input
        type={type}
        className={cx(styles.input)}
        disabled={disabled}
        aria-disabled={disabled}
        {...props}
      />
      <span aria-hidden="true" className={styles.fauxCheckbox}></span>
      {(label || errorMessage) && (
        <span
          className={cx(styles.label, {
            [styles.disabled]: disabled,
          })}
        >
          {label}
          {errorMessage || ''}
        </span>
      )}
    </label>
  );
};

export default Checkbox;
