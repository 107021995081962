import React from 'react';
import Link from 'next/link';

import Slide from 'components/Animations/Slide';
import Clickable from 'components/Clickable';
import Container from 'components/Container';
import Dialog from 'components/Dialog';
import { Modal } from 'components/Modal';
import Spaced from 'components/Spaced';
import Typography from 'components/Typography';
import { STATIC_NAV_ITEMS } from 'consts';
import { LINKS, SOCIAL_LINKS } from 'containers/Footer';
import { useApp } from 'contexts/AppContext';
import LogoSVG from 'icons/logo.svg';
import styles from './MainMenu.module.css';

interface MainMenuNavItemProps {
  name: string;
  href: string;
}

const MainMenuNavItem = React.forwardRef<HTMLElement, MainMenuNavItemProps>(
  function MainMenuNavItem({ name, href }, ref) {
    return (
      <span ref={ref}>
        <Link href={href}>
          <Clickable>
            <Typography uppercase variant="big" className={styles.navItem}>
              {name}
            </Typography>
          </Clickable>
        </Link>
      </span>
    );
  },
);

const MainMenu = () => {
  const { menuOpen, store, setMenuOpen } = useApp();
  const { menu } = store;

  const onClose = () => setMenuOpen(false);

  return (
    <Dialog open={menuOpen} onClose={onClose}>
      <Slide in={menuOpen} direction="x">
        <Modal
          background="offwhite"
          onClose={onClose}
          position="right"
          className={styles.container}
          padding="none"
        >
          <Container className={styles.center}>
            <Spaced spacing={3}>
              <LogoSVG className={styles.logo} alt="Jacksons.se" />
              <nav className={styles.nav}>
                {menu.map(({ path, name }, i) => (
                  <MainMenuNavItem href={`/products/category/${path}`} name={name} key={i} />
                ))}

                {STATIC_NAV_ITEMS.map(({ name, href }, i) => (
                  <MainMenuNavItem href={href} name={name} key={i} />
                ))}
              </nav>

              <footer>
                <Spaced spacing={3}>
                  <ul>
                    {LINKS.map(({ label, href }, i) => (
                      <li key={i}>
                        <Link href={href}>
                          <Clickable>
                            <Typography block uppercase variant="small">
                              {label}
                            </Typography>
                          </Clickable>
                        </Link>
                      </li>
                    ))}
                  </ul>

                  <ul>
                    {SOCIAL_LINKS.map(({ label, href }, i) => (
                      <li key={i}>
                        <Link href={href}>
                          <Clickable>
                            <Typography block uppercase variant="small">
                              {label}
                            </Typography>
                          </Clickable>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Spaced>
              </footer>
            </Spaced>
          </Container>
        </Modal>
      </Slide>
    </Dialog>
  );
};

export default MainMenu;
