import Block from 'components/Block';
import Container from 'components/Container';
import { cx } from 'utils';
import styles from './Notification.module.css';

export type NotificationProps = React.HTMLProps<HTMLDivElement>;

const Notification = ({ children, className, ...props }: NotificationProps) => {
  return (
    <Block padding="none" background="red" className={cx(styles.root, className)} {...props}>
      <Container>{children}</Container>
    </Block>
  );
};

export default Notification;
