import React from 'react';

import Input, { BaseInputProps } from '../Base/Input';
import InputError from '../InputError';
import InputLabel from '../InputLabel';
import { FieldProps } from './FieldProps';

export type InputFieldProps = FieldProps<BaseInputProps>;

const InputField = ({
  block = false,
  disabled = false,
  error = false,
  errorMessage,
  label,
  required,
  ...props
}: InputFieldProps) => {
  const common = { required, disabled, block, error };

  return (
    <InputLabel label={label} {...common}>
      <Input {...common} {...props} />
      <InputError>{error ? errorMessage : ''}</InputError>
    </InputLabel>
  );
};

export default InputField;
