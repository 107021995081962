import React, { RefObject } from 'react';
import cx from 'classnames';

import styles from '../Base.common.module.css';

export interface BaseInputProps extends React.HTMLProps<HTMLInputElement> {
  block?: boolean;
  error?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  errorMessage?: string;
}

/**
 * BaseInput
 * A Base Input component with minimal styling and an error state
 * @param error boolean indicating an error occured.
 * @param block render as a block-level element
 * @param inputRef regular ref exposed as inputRef
 */

const BaseInput: React.FCWithChildren<BaseInputProps> = ({
  className,
  error = false,
  block = false,
  errorMessage,
  inputRef,
  ...rest
}): React.ReactElement<HTMLInputElement> => {
  return (
    <input
      aria-invalid={Boolean(error || errorMessage)}
      className={cx(styles.root, { [styles.block]: block }, className)}
      ref={inputRef}
      {...rest}
    />
  );
};

export default BaseInput;
