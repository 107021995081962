import React, { HTMLProps } from 'react';
import cx from 'classnames';

import styles from '../Base.common.module.css';

export interface BaseTextAreaProps extends HTMLProps<HTMLTextAreaElement> {
  block?: boolean;
  resizable?: boolean;
  error?: boolean;
  errorMessage?: string;
}

/**
 * BaseTextArea
 * A Base Input component with minimal styling and an error state
 * @param error boolean indicating an error occured.
 * @param block render as a block-level element
 */

const BaseTextArea: React.FCWithChildren<BaseTextAreaProps> = ({
  className,
  errorMessage,
  resizable = true,
  error = false,
  block = false,
  ...props
}) => {
  // const hasError = meta.submitError || (meta.touched && meta.error);

  return (
    <textarea
      aria-invalid={Boolean(error || errorMessage)}
      className={cx(
        styles.root,
        styles.textAreaRoot,
        { [styles.preventResize]: resizable === false, [styles.block]: block },
        className,
      )}
      {...props}
    />
  );
};

export default BaseTextArea;
