import React from 'react';

import { cx } from 'utils';
import styles from './InputError.module.css';

export type InputErrorProps = {
  children?: string;
  className?: string;
};

const InputError = ({ children, className }: InputErrorProps) => {
  return <span className={cx(styles.root, className)}>{children}</span>;
};

export default InputError;
