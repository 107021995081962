import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { SEARCH } from 'routes';

import Clickable from 'components/Clickable';
import Container from 'components/Container';
import NavItem from 'components/NavItem';
import ScreenReaderOnly from 'components/ScreenReaderOnly';
import { STATIC_NAV_ITEMS } from 'consts';
import EnquiryDialog from 'containers/EnquiryDialog';
import { LazyItemRequest } from 'containers/ItemRequest';
import MainMenu from 'containers/MainMenu';
import SearchBar from 'containers/SearchBar';
import { useApp } from 'contexts/AppContext';
import { useEnquiries } from 'contexts/EnquiryContext';
import useOnScreen from 'hooks/useOnScreen';
import useResizeObserver from 'hooks/useResizeObserver';
import LogoSVG from 'icons/logo.svg';
import MenuSVG from 'icons/menu.svg';
import { cx } from 'utils';
import styles from './Nav.module.css';

export type NavProps = React.HTMLProps<HTMLDivElement>;

const Nav: React.FCWithChildren<NavProps> = props => {
  const router = useRouter();
  const { setMenuOpen, store, searchOpen } = useApp();
  const { menu } = store;
  const ref = React.useRef<HTMLDivElement>(null);
  const { width = 0 } = useResizeObserver({ ref });
  const { stored, open: enquiryDialogOpen } = useEnquiries();

  /** Change to mobile navigation when all of the items in the nav can't be seen at once */
  const menuVisible = useOnScreen(ref);
  const [menuOverflow, setMenuOverflow] = React.useState(false);

  const [routerHref] = router.asPath.split('?');

  React.useEffect(() => {
    if (ref.current) {
      const { clientWidth, scrollWidth } = ref.current;
      const isOverflowing = clientWidth < scrollWidth;
      setMenuOverflow(!menuVisible || isOverflowing);
    }
  }, [width, menuVisible]);

  return (
    <>
      <nav className={styles.root} {...props}>
        <>{stored.length > 0 && !enquiryDialogOpen && <LazyItemRequest />}</>

        <Container>
          <div className={styles.content}>
            <Link href="/" data-cy="top-nav-home">
              <ScreenReaderOnly>Jacksons.se</ScreenReaderOnly>
              <LogoSVG className={styles.logo} alt="Jacksons.se" />
            </Link>
            <div
              // eslint-disable-next-line css-modules/no-undef-class
              className={cx(styles.items, { [styles.hide]: menuOverflow })}
              ref={ref}
              aria-hidden={menuOverflow}
            >
              {menu.map(({ name, path }, i) => {
                const href = `/products/category/${path}`;

                return (
                  <Link passHref href={href} key={i} legacyBehavior>
                    <NavItem active={href === routerHref}>{name}</NavItem>
                  </Link>
                );
              })}

              {STATIC_NAV_ITEMS.map(({ name, href }, i) => (
                <Link passHref href={href} key={i} legacyBehavior>
                  <NavItem active={href === routerHref}>{name}</NavItem>
                </Link>
              ))}
              <span className={styles.spacer} />
              <Link href="/contact" passHref legacyBehavior>
                <NavItem>CONTACT</NavItem>
              </Link>
            </div>

            <SearchBar
              placeholder="Search designers, year, style etc."
              onSubmit={search => {
                router.push({
                  pathname: SEARCH.pathname,
                  query: {
                    search,
                  },
                });
              }}
            />

            {menuOverflow && !searchOpen && (
              <Clickable className={styles.menuToggle} onClick={() => setMenuOpen(true)}>
                <ScreenReaderOnly>Show menu</ScreenReaderOnly>
                <MenuSVG height={20} />
              </Clickable>
            )}
          </div>
        </Container>
      </nav>
      {menuOverflow && <MainMenu />}
      <EnquiryDialog />
    </>
  );
};

export default Nav;
