import React from 'react';
import Link from 'next/link';
import { ABOUT, CONTACT, PRIVACY, PRODUCERS } from 'routes';

import Block from 'components/Block';
import Clickable from 'components/Clickable';
import Container from 'components/Container';
import Typography from 'components/Typography';
import { cx } from 'utils';
import styles from './Footer.module.css';

export type FooterProps = React.HTMLProps<HTMLDivElement>;

/**
 * Footer
 */

export const LINKS = [
  { label: 'About', href: { pathname: ABOUT.pathname } },
  { label: 'Contact', href: { pathname: CONTACT.pathname } },
  { label: 'Producers', href: { pathname: PRODUCERS.pathname } },
  { label: 'Privacy Policy', href: { pathname: PRIVACY.pathname } },
];

export const SOCIAL_LINKS = [
  { label: 'Instagram', href: 'https://www.instagram.com/jacksons_design' },
  { label: 'Vimeo', href: 'https://vimeo.com/channels/jacksondesign/' },
  { label: 'Facebook', href: 'https://www.facebook.com/JacksonsDesign/' },
  { label: 'ISSUU', href: 'https://issuu.com/jacksondesign/' },
];

const Footer: React.FCWithChildren<FooterProps> = props => {
  return (
    <Block background="offblack" component="footer" className={styles.root} {...props}>
      <Container className={cx(styles.links)}>
        <div className={styles.groups}>
          <div className={styles.group}>
            {LINKS.map(({ label, href }, k) => (
              <Link href={href} key={k} passHref legacyBehavior>
                <Clickable>
                  <Typography variant="small" uppercase>
                    {label}
                  </Typography>
                </Clickable>
              </Link>
            ))}
          </div>
          <div className={styles.group}>
            {SOCIAL_LINKS.map(({ label, href }, k) => (
              <Link href={href} key={k} passHref legacyBehavior>
                <Clickable>
                  <Typography variant="small" uppercase>
                    {label}
                  </Typography>
                </Clickable>
              </Link>
            ))}
          </div>
        </div>

        <div>
          <Typography variant="small" color="faded" uppercase>
            © {new Date().getFullYear()} Jackson Design
          </Typography>
        </div>
      </Container>
    </Block>
  );
};

export default Footer;
