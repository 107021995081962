import React from 'react';

import Select, { SelectInputProps } from '../Base/Select';
import InputError from '../InputError';
import InputLabel from '../InputLabel';
import { FieldProps } from './FieldProps';

export type SelectFieldProps = FieldProps<SelectInputProps>;

const SelectField = ({
  block = false,
  disabled = false,
  error = false,
  errorMessage,
  label,
  required,
  ...props
}: SelectFieldProps) => {
  const common = { required, disabled, block, error };

  return (
    <InputLabel label={label} {...common}>
      <Select {...common} {...props} />
      <InputError>{error ? errorMessage : ''}</InputError>
    </InputLabel>
  );
};

export default SelectField;
