import React from 'react';
import { FieldRenderProps } from 'react-final-form';

type Mprops = {
  errorMessage?: string;
};

/**
 * WithFinalForm<ComponentProps>
 * - Takes all the props of `FieldRenderProps` from Final Form.
 * Intended as a thin wrapper to make components compliant with final form.
 *
 * Use as `render` prop to `<Field/>`:
 * <Field render={WithFinalForm(Component)} name="test" {...componentProps}/>
 *
 * ISSUES:
 *
 * 1. The props from the underlying component is not properly passed on to the HOC:ed component,
 *    sadly disabling in-editor typechecking and propType suggestions.
 */

function WithFinalForm<P>(Component: React.ComponentType<P>) {
  const ComponentWithFinalForm: React.FCWithChildren<
    P & Mprops & FieldRenderProps<string, HTMLElement>
  > = ({ input, meta, ...props }) => {
    const error =
      meta.submitError || meta.error
        ? [meta.submitError, meta.error].filter(Boolean).join(', ')
        : undefined;

    return (
      <Component errorMessage={meta.touched ? error : undefined} {...input} {...(props as P)} />
    );
  };
  return ComponentWithFinalForm;
}

export default WithFinalForm;
