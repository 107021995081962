import React from 'react';
import cx from 'classnames';

import common from '../Base.common.module.css';
import styles from './Select.module.css';
export interface SelectInputProps extends React.HTMLProps<HTMLSelectElement> {
  children?: Array<React.ReactElement<HTMLOptionElement>> | React.ReactElement<HTMLOptionElement>;
  placeholder?: string;
  block?: boolean;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  nullable?: boolean;
}

/**
 * BaseSelect
 * A Base Select component with minimal styling and an error state
 * @param error boolean indicating an error occured.
 * @param block render as a block-level element
 */
const BaseSelect: React.FCWithChildren<SelectInputProps> = ({
  className,
  children,
  placeholder,
  errorMessage,
  error = false,
  nullable = false,
  block = false,
  ...props
}) => {
  // const hasError = meta.submitError || (meta.touched && meta.error);
  return (
    <span className={cx(styles.wrapper, { [common.block]: block })}>
      <select
        aria-invalid={Boolean(error || errorMessage)}
        defaultChecked
        className={cx(common.root, styles.root, { [common.block]: block }, className)}
        {...props}
      >
        {placeholder && (
          <option value="" aria-disabled={!nullable} disabled={!nullable}>
            {placeholder}
          </option>
        )}
        {children}
      </select>
    </span>
  );
};

export default BaseSelect;
