import Clickable from 'components/Clickable';
import ScreenReaderOnly from 'components/ScreenReaderOnly';
import Typography from 'components/Typography';
import { Product } from 'types/Product';
import { stringifyMultipleDesigners } from 'utils';
import makeDevImageSrc from 'utils/makeDevImgSrc';
import styles from './ReceiptLine.module.css';

export interface ReceiptLineProps {
  product: Product;
  onRemove?: () => void;
}
const ReceiptLine = ({ product, onRemove }: ReceiptLineProps) => {
  const { name, designers, browse_thumb, browse_thumb_x2 } = product;

  const thumb = makeDevImageSrc(browse_thumb || '');
  const thumb_x2 = makeDevImageSrc(browse_thumb_x2 || '');

  return (
    <div className={styles.root}>
      <img
        src={makeDevImageSrc(thumb)}
        srcSet={`${thumb} 420w, ${thumb_x2} 840w`}
        alt={name}
        className={styles.image}
      />
      <div className={styles.text}>
        <Typography variant="small" block>
          {name}
        </Typography>
        <Typography variant="small" block color="faded">
          {stringifyMultipleDesigners(designers)}
        </Typography>
      </div>
      {onRemove && (
        <Clickable className={styles.close} onClick={onRemove}>
          <ScreenReaderOnly>Remove {name}</ScreenReaderOnly>
        </Clickable>
      )}
    </div>
  );
};

export default ReceiptLine;
