import CheckboxInput, { CheckboxProps } from './Base/Checkbox';
import BaseInput, { BaseInputProps } from './Base/Input';
import BaseSelect, { SelectInputProps } from './Base/Select';
import BaseTextArea, { BaseTextAreaProps } from './Base/TextArea';
import InputField, { InputFieldProps } from './Field/InputField';
import SelectField, { SelectFieldProps } from './Field/SelectField';
import TextAreaField, { TextAreaFieldProps } from './Field/TextAreaField';
import WithFinalForm from './WithFinalForm';

/** Regular Inputs */
/** These are just inputs, without error handling or labels */
export { default as Input } from './Base/Input';
export { default as Select } from './Base/Select';
export { default as TextArea } from './Base/TextArea';
export { default as Checkbox } from './Base/Checkbox';

/** Field is a wrapper around the Inputs, and can take the shape of any input.
 * Adds Label functionality and error displays
 */
export { default as InputField } from './Field/InputField';
export { default as SelectField } from './Field/SelectField';
export { default as TextAreaField } from './Field/TextAreaField';

/** With Final Form,
 * to be used as a render prop to <Field/>
 * example:
 * <Field render={FF_LabeledInput} type="number" label="A number"/>
 */

// FF Inputs
export const FF_Input = WithFinalForm<BaseInputProps>(BaseInput);
export const FF_TextArea = WithFinalForm<BaseTextAreaProps>(BaseTextArea);
export const FF_Select = WithFinalForm<SelectInputProps>(BaseSelect);
export const FF_Checkbox = WithFinalForm<CheckboxProps>(CheckboxInput);

// FF Fields
export const FF_InputField = WithFinalForm<InputFieldProps>(InputField);
export const FF_TextAreaField = WithFinalForm<TextAreaFieldProps>(TextAreaField);
export const FF_SelectField = WithFinalForm<SelectFieldProps>(SelectField);
